import { template as template_963e6e512dfb41a4806d6a3184479664 } from "@ember/template-compiler";
const FKLabel = template_963e6e512dfb41a4806d6a3184479664(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
