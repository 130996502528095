import { template as template_07a6134578df43fb9028af4a4e79f6ef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_07a6134578df43fb9028af4a4e79f6ef(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
