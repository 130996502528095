import { template as template_fff2f1473d8f485ab4b8111176018649 } from "@ember/template-compiler";
const FKText = template_fff2f1473d8f485ab4b8111176018649(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
